import React, { useContext } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import { MatchmakingContext } from '../context/index';

export const MessageForm = () => {
    const matchmakingContext = useContext(MatchmakingContext);

    const closeModal = () => {
        matchmakingContext.setMessageModalActive(false);
    };

    return (
        <MDBModal show={matchmakingContext.messageModalActive} centered>
            <form onSubmit={(e)=> { e.preventDefault(); closeModal()} } >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol md="12">
                                    <h4 style={{textAlign: 'center'}} className="modal-title">{matchmakingContext.messageModalMessage}</h4>
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>

                        <MDBModalFooter className="border-0 justify-content-center">
                            <MDBBtn style={{width: '200px'}} color="primary" type="submit">OK</MDBBtn>
                        </MDBModalFooter>
                            </MDBModalContent>
                </MDBModalDialog>
            </form>
        </MDBModal>
    );
};