import React, { useState, useContext } from 'react';
import { MDBContainer, MDBRow, MDBBtn, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdownLink } from "mdb-react-ui-kit";
import { useNavigate  } from "react-router-dom";
import { GameFinderContext } from './context'

const GameFinder = () => {
    let navigate = useNavigate ();
    const gameFinderContext = useContext(GameFinderContext);
    const [selectedBenderColor, setSelectedBenderColor] = useState('danger');

    const selectQueueType = (amount) => {
        gameFinderContext.setSelectedQueueType(amount);
    };

    const selectBenderType = (benderType, benderColor) => {
        gameFinderContext.setSelectedBenderType(benderType);
        setSelectedBenderColor(benderColor);
    };

    return(
        <MDBContainer fluid className="pb-4 pt-4 orange lighten-3 lobby-container">
            <MDBContainer>
                <MDBRow className="h-auto">
                    <MDBCol md="12" bg="#fff" className="mb-5 d-flex justify-content-center">
                        <h1>Game finder</h1>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mb-5 h-auto">
                    <MDBCol md="12" bg="#fff" className="d-flex justify-content-center">
                        <h4>Select amount of players</h4>
                    </MDBCol>

                    <MDBCol md="12" className="d-flex justify-content-center">
                        <MDBDropdown dropdown group>
                            <MDBDropdownToggle>
                                {gameFinderContext.selectedQueueType + 1} Players
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem>
                                    <MDBDropdownLink onClick={() => {selectQueueType(1)}}>2 Players</MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBDropdownLink onClick={() => {selectQueueType(2)}}>3 Players</MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBDropdownLink onClick={() => {selectQueueType(3)}}>4 Players</MDBDropdownLink>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="h-auto">
                    <MDBCol md="12" bg="#fff" className="d-flex justify-content-center">
                        <h4>Select bender type</h4>
                    </MDBCol>

                    <MDBCol md="12" className="d-flex justify-content-center">
                        <MDBDropdown size="lg">
                            <MDBDropdownToggle caret="true" color={selectedBenderColor}>{gameFinderContext.selectedBenderType}</MDBDropdownToggle>
                            <MDBDropdownMenu color="danger">
                                <MDBDropdownMenu>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {selectBenderType('fire', 'danger')}}>Fire</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {selectBenderType('air', 'light-blue lighten-5')}}>Air</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {selectBenderType('earth', 'brown')}}>Earth</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {selectBenderType('water', 'primary')}}>Water</MDBDropdownLink>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBCol>

                    <MDBCol md="12" className="mt-5 d-flex justify-content-center">
                        <MDBBtn onClick={() => {navigate("/")}} size="lg" color="primary">Back</MDBBtn>
                        <MDBBtn onClick={() => {navigate("/queue")}} size="lg" color="primary">Find game</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    )
};

export default GameFinder;