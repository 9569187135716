import { PlayerMovement } from "./PlayerMovement";
import {PlayerTechniques} from "./PlayerTechniques";
import Phaser from 'phaser';

export class Player{
    constructor(props){
        this.game = props.game;
        this.scene = this.game.scene;

        this.playerSprite = this.scene.physics.add.sprite(1000, 200, 'player');
        this.health = 100;
        this.benderType = props.benderType;
        
        this.game.player = this;

        this.playerMovement = new PlayerMovement(this.game);
        this.playerTechniques = new PlayerTechniques(this.game);

        //  TODO: change this to use the react UI
        this.healthText = this.game.scene.add.text(100, 100, '');
    }

    update(time, delta){
        if(this.playerMovement){
            this.playerMovement.update(time, delta);
        }
    }

    updateData(data){
        this.health = data.health;
        this.healthText.text = data.health;

        this.playerMovement.updateData(data);
    }

    emitData(data){
        this.game.multiPlayerController.emitPlayerData(data);
    }
}