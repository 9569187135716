import React , { useState, useContext } from 'react';
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MultiplayerContext } from '../../matchmaking/context'

export const Notifications = () => {
    const multiplayerContext = useContext(MultiplayerContext);
    const socket = multiplayerContext.socket;

    const [notificationActive, setNotificationActive] = useState(false);
    const [notification, setNotification] = useState('');

    socket.on('updateGameStartCountdown', (data) => {
        setNotificationActive(true);
        setNotification('Time until game starts: ' + data.timeToStart);
    });

    socket.on('removeGameStartCountdown', () => {
        setNotificationActive(false);
    });

    return(
        <MDBRow style={{left: 0, right: 0, zIndex: 1, pointerEvents: 'none'}} className={(notificationActive) ? 'd-flex position-absolute align-items-center justify-content-center h-100' : 'd-none'}>
            <MDBCol style={{color: '#fff', fontSize: '34px'}} md="4" className="d-flex flex-wrap justify-content-center">
                {notification}
            </MDBCol>
        </MDBRow>
    )
};