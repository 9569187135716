import React, { useContext, useEffect  } from "react";
import { MDBContainer, MDBRow, MDBBtn, MDBCol } from "mdb-react-ui-kit";
import { v4 } from 'uuid';
import { RoomList, RoomForm } from './lobby/';
import { Link, useNavigate  } from "react-router-dom";
import { LobbyContext, MatchmakingContext, MultiplayerContext } from './context'

const Lobby = () => {
    const matchmakingContext = useContext(MatchmakingContext);
    const lobbyContext = useContext(LobbyContext);
    const multiplayerContext = useContext(MultiplayerContext);

    const socket = multiplayerContext.socket;
    let navigate = useNavigate ();

    //  useEffect to reload the rooms upon joining the lobby, passing the empty array to prevent function on component re-render
    useEffect(() => {
        socket.emit('requestRoomReload');
    }, []);

    socket.on('playerConnected', (rooms) => {
        matchmakingContext.setActiveRooms(rooms);
    });

    socket.on('disbandRoom', () => {
        console.log('disband my room');
    });

    const createRoomModal = () => {
        lobbyContext.setRoomModalActive(true);
    };

    const reloadRooms = () => {
        socket.emit('requestRoomReload');
    };

    const createRoom = (data) => {
        lobbyContext.setRoomModalActive(false);

        const roomID = v4();
        socket.emit('createRoom',  {roomID: roomID, roomName: data.roomName, playerCount: data.playerCount});

        matchmakingContext.setIsHost(true);
        matchmakingContext.setActiveRoomID(roomID);
        matchmakingContext.setActiveRoomName(data.roomName);
        matchmakingContext.setActiveRoomPlayerCount(data.playerCount);
        navigate('/room');
    };

    socket.on('reloadRooms', (rooms) => {
        console.log('reload rooms', rooms);

        matchmakingContext.setActiveRooms(rooms);
    });

    return(
        <MDBContainer fluid className="pb-4 pt-4 orange lighten-3 lobby-container">
            <RoomForm createRoom={createRoom} />

            <MDBContainer>
                <MDBRow>
                    <MDBCol bg="#000" className="pt-3 pb-3 stylish-color d-flex justify-content-center" md="9">
                        <RoomList socket={socket} />
                    </MDBCol>
                    <MDBCol md="3">
                        <MDBBtn onClick={() => {createRoomModal()}} size="lg" color="primary" className="w-100 mt-0">Create room</MDBBtn>
                        <MDBBtn onClick={() => {reloadRooms()}} size="lg" color="primary" className="w-100 mt-0">Reload rooms</MDBBtn>
                        <Link to="/">
                            <MDBBtn style={{width: '200px'}} size="lg" color="primary" className="w-100 mt-0">Back</MDBBtn>
                        </Link>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    )
};

export default Lobby;