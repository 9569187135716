import React, { useState } from "react";
export const GameFinderContext = React.createContext();

export const GameFinderContextProvider = (props) => {
    const [selectedQueueType, setSelectedQueueType] = useState(1);
    const [selectedBenderType, setSelectedBenderType] = useState('fire');

    const GameFinderContextValue = {
        selectedQueueType, setSelectedQueueType,
        selectedBenderType, setSelectedBenderType
    };

    return(
        <GameFinderContext.Provider value={GameFinderContextValue}>
            {props.children}
        </GameFinderContext.Provider>
    )
};
