import { MDBBadge, MDBListGroup, MDBListGroupItem, MDBContainer, MDBRow, MDBBtn, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem} from "mdb-react-ui-kit";
import React, { useState, useContext } from 'react';
import { useNavigate  } from "react-router-dom";
import { MatchmakingContext, MultiplayerContext } from './context'

const Room = () => {
    const matchmakingContext = useContext(MatchmakingContext);
    const multiplayerContext = useContext(MultiplayerContext);

    const socket = multiplayerContext.socket;
    const [players, setPlayers] = useState({'player': {id: socket.id, username: matchmakingContext.username, host: true, ready: false, benderType: matchmakingContext.selectedBenderType}});
    const [selectedBenderColor, setSelectedBenderColor] = useState('danger');
    const [ready, setReady] = useState(false);
    let navigate = useNavigate();

    socket.on('reloadPlayers', (players) => {
        setPlayers(players);
    });

    socket.on('closeRoom', () => {
        navigate('/lobby');
    });

    socket.on('gameCreationInstantiated', (data) => {
        const gamePlayers = data.players;
        const playersNeeded = Object.keys(gamePlayers).length;

        if(gamePlayers[socket.id]){
            socket.emit('joinGame', {players: gamePlayers, gameID: data.gameID, playersNeeded: playersNeeded});
            navigate('/game');
        };
    });

    const leaveRoom = () => {
        socket.emit('leaveRoom', {roomID: matchmakingContext.activeRoomID});
        navigate('/lobby');
        matchmakingContext.setIsHost(false);
    };

    const startGame = () => {
        let playersReady = true;
        Object.keys(players).map((player) => {
            const gamePlayer = [players[player]][0];

            if(!gamePlayer.host && !gamePlayer.ready){
                playersReady = false;
            }
        });

        //  Check if players have checked ready before starting the game
        if(playersReady) {
            socket.emit('startGame', {players: players});
        } else {
            matchmakingContext.setMessageModalActive(true);
            matchmakingContext.setMessageModalMessage('All players must be ready');
        }
    };

    const changeReady = () => {
        const newReady = !ready;
        setReady(newReady);
        socket.emit('changeReady', {roomID:  matchmakingContext.activeRoomID, id: socket.id, ready: newReady, benderType: matchmakingContext.selectedBenderType});
    };

    const selectBenderType = (benderType, benderColor) => {
        matchmakingContext.setSelectedBenderType(benderType);
        setSelectedBenderColor(benderColor);

        //  Re-use change ready so the host can set bender type
        socket.emit('changeReady', {roomID:  matchmakingContext.activeRoomID, id: socket.id, ready: ready, benderType: benderType});
    };

    const BenderTypeDropdown = () => {
        return (
            <MDBDropdown className="w-100 mb-5" size="lg">
                <MDBDropdownToggle disabled={ready} className="w-100 mt-0" caret color={selectedBenderColor}>{matchmakingContext.selectedBenderType}</MDBDropdownToggle>
                <MDBDropdownMenu className="w-100" color="danger">
                    <MDBDropdownItem onClick={() => {selectBenderType('fire', 'danger')}}>Fire</MDBDropdownItem>
                    <MDBDropdownItem onClick={() => {selectBenderType('air', 'light-blue lighten-5')}}>Air</MDBDropdownItem>
                    <MDBDropdownItem onClick={() => {selectBenderType('earth', 'brown')}}>Earth</MDBDropdownItem>
                    <MDBDropdownItem onClick={() => {selectBenderType('water', 'primary')}}>Water</MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    const ActionButtons = () => {
        if(matchmakingContext.isHost){
            return(
                <MDBCol md="3">
                    <BenderTypeDropdown />

                    <MDBBtn className="w-100" onClick={() => {startGame()}} size="lg" color="success">Start game</MDBBtn>
                    <MDBBtn className="w-100" onClick={() => {leaveRoom()}} size="lg" color="danger">Leave room</MDBBtn>
                </MDBCol>
            )
        } else {
            return(
                <MDBCol md="3">
                    <BenderTypeDropdown />

                    <MDBBtn className="w-100" onClick={() => {changeReady()}} size="lg" color={(ready) ? 'danger' : 'success'}>{(ready) ? 'Not ready' : 'Ready'}</MDBBtn>
                    <MDBBtn className="w-100" width="200px" onClick={() => {leaveRoom()}} size="lg" color="danger">Leave room</MDBBtn>
                </MDBCol>
            )
        }
    };

    const ReadyLabel = (props) => {
        const player = props.player;

        if(!player.host) {
            return (
                <MDBBadge className="status-badge" color={(player.ready) ? 'success' : 'danger'}>
                    {(player.ready) ? 'Ready' : 'Not ready'}
                </MDBBadge>
            )
        } else {
            return (
                <MDBBadge className="status-badge" color='primary' >
                    Host
                </MDBBadge>
            )
        }
    };

    const PlayerList = () => {
        return(
            Object.keys(players).map((key) => {
                return (
                    <MDBListGroupItem key={key} className="mb-2 stylish-color-dark d-flex justify-content-between align-items-center">
                        { players[key].username }

                        <ReadyLabel player={players[key]} />
                    </MDBListGroupItem>
                )
            })
        )
    };

    return (
        <MDBContainer fluid className="pb-4 pt-4 orange lighten-3 lobby-container">
            <MDBContainer>
                <MDBRow className="h-auto">
                    <MDBCol bg="#fff">
                        <h1>{matchmakingContext.activeRoomName}</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{maxHeight: '500px'}}>
                    <MDBCol bg="#000" className="pt-3 pb-3 stylish-color d-flex justify-content-center" md="9">
                        <MDBListGroup className="w-100">
                            <PlayerList />
                        </MDBListGroup>
                    </MDBCol>
                    <ActionButtons />
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    )
};

export default Room;