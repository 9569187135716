export class PlayerTechniques{
    constructor(game){
        this.game = game;
        this.scene = game.scene;
        this.player = game.player;
        this.multiPlayerController = game.multiPlayerController;

        this.techniquesConfig = this.scene.cache.json.get('techniquesConfig');
        this.techniques = this.techniquesConfig[this.player.benderType];

        this.setupTechniques();
    }

    setupTechniques(){
        this.techniques.map((technique) => {
            this.setupTechnique(technique, this.multiPlayerController);
        });
    }

    setupTechnique(technique, multiPlayerController){
        if(technique.inputType === 'key') {
            const keyObj = this.scene.input.keyboard.addKey(technique.input);
            keyObj.on('down', function () {
                multiPlayerController.emitUseTechnique(technique.id);
            })
        } else if(technique.inputType === 'mouse') {
            this.scene.input.on('pointerdown', (pointer) => {
                if(pointer.rightButtonDown() && technique.input === 'pointerDownUpRight' ||
                    pointer.leftButtonDown() && technique.input === 'pointerDownLeft') {

                    multiPlayerController.emitUseTechnique(technique.id);
                }
            });

            this.scene.input.on('pointerup', (pointer) => {
                if(pointer.rightButtonReleased() && technique.input === 'pointerDownUpRight'){
                    multiPlayerController.emitUseTechnique(technique.id);

                }
            });
        }
    }
}