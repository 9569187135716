import React, { useContext } from "react";
import { MDBListGroup, MDBListGroupItem, MDBContainer, MDBRow, MDBBtn, MDBCol} from "mdb-react-ui-kit";
import { useNavigate  } from "react-router-dom";
import { LobbyContext, MatchmakingContext, MultiplayerContext } from '../context'


export const RoomList = () => {
    const lobbyContext = useContext(LobbyContext);
    const matchmakingContext = useContext(MatchmakingContext);
    const multiplayerContext = useContext(MultiplayerContext);

    const rooms = matchmakingContext.activeRooms;
    const socket = multiplayerContext.socket;
    let navigate = useNavigate ();

    const joinRoom = (roomID, roomName, playerAmount) => {
        if(Object.keys(rooms[roomID].clients).length >= playerAmount){
            matchmakingContext.setMessageModalActive(true);
            matchmakingContext.setMessageModalMessage('Room is full');

            return;
        }

        socket.emit('joinRoom',  {roomID: roomID});
        matchmakingContext.setActiveRoomID(roomID);
        matchmakingContext.setActiveRoomName(roomName);
        matchmakingContext.setActiveRoomPlayerCount(playerAmount);

        navigate('/room');
    };

    const Rooms = () => {
        if( Object.keys(rooms).length > 0) {
            return (
                Object.keys(rooms).map((key) => {
                    return (
                        <MDBListGroupItem key={key} className="mb-2 stylish-color-dark d-flex align-items-center">
                            <MDBCol>{rooms[key].roomName}</MDBCol>
                            <MDBCol>{'(' +  Object.keys(rooms[key].clients).length + '/' + rooms[key].playerCount + ')'}</MDBCol>
                            <MDBCol className="d-flex justify-content-end"><MDBBtn onClick={() => {joinRoom(rooms[key].id, rooms[key].roomName, rooms[key].playerCount)}} size="sm" color="success">Join</MDBBtn></MDBCol>
                        </MDBListGroupItem>
                    )
                })
            )
        } else return null;
    };

    return(
        <MDBContainer>
            <MDBRow>
                <MDBListGroup className="w-100">
                    <Rooms />
                </MDBListGroup>
            </MDBRow>
        </MDBContainer>
    )
};