import React from 'react';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBBtn, MDBCol } from "mdb-react-ui-kit";
import { UserForm } from './lobby/UserForm';

const Menu = (props) => {
    return (
        <MDBContainer fluid className="orange lighten-3 matchmaking-menu-container">
            <UserForm socket={props.socket} />

            <MDBRow>
                <MDBCol className="d-flex justify-content-center" md="12">
                    <Link to="/lobby">
                        <MDBBtn size="lg" color="primary" style={{width: '250px'}}>Multiplayer lobby</MDBBtn>
                    </Link>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" md="12">
                    <Link to="/gameFinder">
                        <MDBBtn size="lg" color="primary" style={{width: '250px'}}>Find a game</MDBBtn>
                    </Link>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" md="12">
                    <MDBBtn size="lg" color="primary" style={{width: '250px'}}>Quit</MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
};

export default Menu;