import React, { useState } from "react";

export const MultiplayerContext = React.createContext();

export const MultiplayerContextProvider = (props) => {
    const socket = props.socket;

    const multiplayerContextValue = {
        socket
    };

    return(
        <MultiplayerContext.Provider value={multiplayerContextValue}>
            {props.children}
        </MultiplayerContext.Provider>
    )
};