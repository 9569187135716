import React, { useState, useContext } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import { LobbyContext } from '../context';

export const RoomForm = (props) => {
    const [roomName, setRoomName] = useState('');
    const [playerCount, setPlayerCount] = useState(2);
    const lobbyContext = useContext(LobbyContext);

    const decreasePlayerAmount = () => {
        if(playerCount > 1) {
            setPlayerCount(playerCount - 1);
        }
    };

    const increasePlayerAmount = () => {
        if(playerCount < 4) {
            setPlayerCount(playerCount + 1);
        }
    };

    const changeRoomName = event => {
        setRoomName(event.target.value);
    };

    const submitRoomForm = (e) => {
        e.preventDefault();
        (roomName !== '') ? props.createRoom({roomName: roomName, playerCount: playerCount}) : lobbyContext.setRoomModalActive(false);
    };

    return (
        <MDBModal show={lobbyContext.roomModalActive} centered>
            <form onSubmit={ submitRoomForm } >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader toggle={submitRoomForm}>Create a new room</MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow>
                                <MDBCol md="12">
                                    <input
                                        value={ roomName }
                                        onChange={ changeRoomName }
                                        type="text"
                                        id="roomNameForm"
                                        className="form-control"
                                        placeholder="Room name"
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol className="d-flex align-items-center" md="12">
                                    <span>Players: </span>
                                    <MDBBtn size="sm" color="black-text" className="px-2 z-depth-0 transparent" style={{fontSize: '26px'}} onClick={decreasePlayerAmount}>-</MDBBtn>
                                    <input className="quantity form-control" name="quantity" value={playerCount} style={{width: 'auto', textAlign: 'center'}} onChange={()=> {return null;}} type="number" />
                                    <MDBBtn size="sm" color="black-text" className="px-2 z-depth-0 transparent" style={{fontSize: '26px'}} onClick={increasePlayerAmount}>+</MDBBtn>
                                </MDBCol>
                            </MDBRow>

                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" type="submit">Submit</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </form>
        </MDBModal>
    );
};