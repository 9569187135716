import React, { useState, useContext } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import { MatchmakingContext, MultiplayerContext } from '../context/index'

export const UserForm = () => {
    const [username, setUsername] = useState('');
    const matchmakingContext = useContext(MatchmakingContext);
    const multiplayerContext = useContext(MultiplayerContext);
    const socket = multiplayerContext.socket;

    const changeUsername = event => {
        setUsername(event.target.value);
    };

    const submitUsername = (e) => {
        e.preventDefault();
        localStorage.setItem('username', username);
        matchmakingContext.setUsername(username);
        multiplayerContext.socket.emit('submitUsername', {id: multiplayerContext.socket.id, username: username});
    };

    //  Check if user has set username before
    socket.on('connect', () => {
        if(localStorage.getItem('username')){
            multiplayerContext.socket.emit('submitUsername', {id: multiplayerContext.socket.id, username: localStorage.getItem('username')});
            return null;
        }
    });

    return (
        <MDBModal show={!localStorage.getItem('username')} centered>
            <form onSubmit={submitUsername}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>Please enter your username</MDBModalHeader>
                        <MDBModalBody>
                            <input
                                value={username}
                                onChange={changeUsername}
                                type="text"
                                id="usernameForm"
                                className="form-control"
                                placeholder="Username"
                                required
                            />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" type="submit">Submit</MDBBtn>
                        </MDBModalFooter>
                            </MDBModalContent>
                </MDBModalDialog>
            </form>
        </MDBModal>
    );
};