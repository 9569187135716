import React, { useState } from "react";
export const LobbyContext = React.createContext();

export const LobbyContextProvider = (props) => {
    const [roomModalActive, setRoomModalActive] = useState(false);

    const lobbyContextValue = {
        roomModalActive, setRoomModalActive
    };

    return(
        <LobbyContext.Provider value={lobbyContextValue}>
            {props.children}
        </LobbyContext.Provider>
    )
};
