import { UIController } from ".//UIController";
import { Player} from "../player/Player";
import {WorldBuilderController} from "./WorldBuilderContoller";
import {MultiPlayerController} from "./MultiPlayerController";

export class GameController{
    constructor(game){
        this.game = game;
        this.scene = game.scene;
        game.gameController = this;

        //  Manage objects in order to call their update functions
        this.movementObjects = [];

        game.multiPlayerController = new MultiPlayerController(game);
        this.game.worldBuilder = new WorldBuilderController(game);
    }

    update(time, delta){
        if(this.game.player) {
            this.game.player.update(time, delta);
            this.game.multiPlayerController.update(time, delta);
        }

        this.movementObjects.map((movementObject) => { movementObject.update(time, delta) } );
    }
}