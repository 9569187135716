import React, {useEffect, useState, useContext} from 'react';
import Phaser from 'phaser'
// import { IonPhaser } from '@ion-phaser/react'
import { ArenaScene } from "./scenes";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { UIController } from "./controllers/UIController";

import { MultiplayerContext } from '../matchmaking/context'
import { GameUIContextProvider } from '../game/context/GameUIContextProvider'

//Default game config object
let gameConfig = {
    width:"100%",
    height: "100%",
    type: Phaser.AUTO,
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 0 },
            debug: false
        }
    },
    scene: [ArenaScene]
};

export const Game = () => {
    /*
     * Initialize game state.
     * Using Object.assign so we dont lose the default properties when destroy is being called
     */
    const multiplayerContext = useContext(MultiplayerContext);
    const socket = multiplayerContext.socket;
    const [initialize, setInitialize] = useState(true);

    const gameObj = new Phaser.Game(gameConfig);
    gameObj.config.socket = socket;

    const GameUIController = () => {
        return(
            <GameUIContextProvider>
                <UIController game={game} />
            </GameUIContextProvider>
        )
    };

    const [game, setGame] = useState(Object.assign({}, gameObj));

    const destroy = () => {
        setInitialize(false);
        setGame(undefined);
    };

    const GameWrapper = React.memo(props => {
       return(
           <>{props.children}</>
       )
    });

    return (
        <GameWrapper>
            <IonPhaser game={game} initialize={initialize} />
            <GameUIController />
        </GameWrapper>
    );
};