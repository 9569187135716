import Phaser from 'phaser'

export class PlayerMovement{
    speed = 600;

    constructor(game) {
        this.game = game;
        this.scene = game.scene;
        this.player = game.player;
        this.playerSprite = game.player.playerSprite;
        this.movementID = 0;

        this.movementKeys = this.scene.input.keyboard.addKeys({
            up: Phaser.Input.Keyboard.KeyCodes.W,
            down: Phaser.Input.Keyboard.KeyCodes.S,
            left: Phaser.Input.Keyboard.KeyCodes.A,
            right: Phaser.Input.Keyboard.KeyCodes.D
        });

        //  Spawn crosshair
        this.game.crosshair = this.scene.physics.add.sprite(100, 100, 'crosshair');
        this.game.crosshair.displayHeight = '10';
        this.game.crosshair.displayWidth = '10';
        this.crosshair = this.game.crosshair;

        this.scene.input.on('pointerdown', () => {
            this.scene.input.mouse.requestPointerLock();
        }, this);

        this.scene.input.on('pointermove', function (pointer) {
            if (this.scene.input.mouse.locked)
            {
                //  Lock crosshair to mouse position
                this.crosshair.x += pointer.movementX;
                this.crosshair.y += pointer.movementY;

                //  Ensures crosshair cannot be moved offscreen
                const screenX = this.scene.cameras.main.centerX;
                const screenY = this.scene.cameras.main.centerY;

                const distX = this.crosshair.x - this.playerSprite.x;
                const distY = this.crosshair.y - this.playerSprite.y;

                if (distX > screenX) {
                    this.crosshair.x = this.playerSprite.x + screenX;
                } else if (distX < -screenX) {
                    this.crosshair.x = this.playerSprite.x - screenX;
                }

                if (distY > screenY) {
                    this.crosshair.y = this.playerSprite.y + screenY;
                } else if (distY < -screenY) {
                    this.crosshair.y = this.playerSprite.y - screenY;
                }
            }
        }, this);
    }

    update(time, delta){
        //  Lock player rotation to mouse position
        this.playerSprite.rotation = Phaser.Math.Angle.Between(this.playerSprite.x, this.playerSprite.y, this.crosshair.x, this.crosshair.y) + Math.PI / 2;

        //  Lock camera to player position
        this.scene.cameras.main.startFollow(this.playerSprite);

        this.movementID = {x: 0, y: 0};

        //  Move crosshair and player
        this.movementID.x = (this.movementKeys.left.isDown) ? -1 : 1;
        this.movementID.y = (this.movementKeys.up.isDown) ? -1 : 1;

        this.movementID.x = (!this.movementKeys.left.isDown && !this.movementKeys.right.isDown) ? 0 : this.movementID.x;
        this.movementID.y = (!this.movementKeys.up.isDown && !this.movementKeys.down.isDown) ? 0 : this.movementID.y;

        //  Pass this to player so the player can add additional data
        this.game.player.emitData({movementID: this.movementID});
    }

    updateData(data){
        const cursorOffset = {x: this.playerSprite.x - data.playerPosition.x, y: this.playerSprite.y - data.playerPosition.y};
        this.game.crosshair.x -= cursorOffset.x;
        this.game.crosshair.y -= cursorOffset.y;

        this.playerSprite.x = data.playerPosition.x;
        this.playerSprite.y = data.playerPosition.y;
    }
}