import React, { useState, useContext } from 'react';
import { MDBBtn, MDBCol, MDBRow, MDBContainer } from "mdb-react-ui-kit";
import { GameUIContext } from '../../game/context/GameUIContextProvider'

export const GameMenu = () => {
    const [gameMenuOpened, setGameMenuOpened] = useState(false);
    const gameUIContext = useContext(GameUIContext);
    const canvas = document.querySelector('canvas');

    const handleActivateMenu = (e) => {
        if(e.keyCode === 80){
            document.removeEventListener("keydown", handleActivateMenu, false);
            gameUIContext.setAllowMenuToggle(false);
            toggleMenu();
        }
    };

    const handleDeactivateMenu = (e) => {
        if(e.keyCode === 80){
            document.removeEventListener("keyup", handleDeactivateMenu, false);

            if(!gameUIContext.allowMenuToggle) {
                gameUIContext.setAllowMenuToggle(true);
            }
        }
    };

    const toggleMenu = () => {
        if(gameUIContext.allowMenuToggle){
            if(gameMenuOpened) {
                canvas.requestPointerLock();
                setGameMenuOpened(false);
            } else {
                document.exitPointerLock();
                setGameMenuOpened(true);
            }
        }
    };

    document.addEventListener("keydown", handleActivateMenu, false);
    document.addEventListener("keyup", handleDeactivateMenu, false);

    return(
        <MDBRow style={{left: 0, right: 0, zIndex: 1}} className={(gameMenuOpened) ? 'd-flex position-absolute align-items-center justify-content-center h-100' : 'd-none'}>
            <MDBCol md="2" className="d-flex flex-wrap">
                <MDBBtn onClick={toggleMenu} className="m-0 mb-4 w-100">Resume</MDBBtn>
                <MDBBtn className="m-0 w-100">Disconnect</MDBBtn>
            </MDBCol>
        </MDBRow>
    )
};