import React from 'react';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MDBProgress } from 'mdbreact';

export const PlayerUI = (props) => {
    const playerData = props.playerData;

    const armor = playerData.armor;
    const health = playerData.health;
    const chi = playerData.chi;

    return(
        <MDBCol className="player-ui-container">
            <div className="health-container">
                <MDBProgress material value={health} height="35px" color="danger" className="resource-bar health">{health}</MDBProgress>
                <MDBProgress material value={armor} height="35px" color="primary" className="resource-bar armor">{armor}</MDBProgress>
            </div>

            <MDBProgress material value={chi} height="35px" color="warning" className="resource-bar chi">{chi}</MDBProgress>
        </MDBCol>
    )
};