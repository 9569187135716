import React, {useState, useContext} from 'react';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { GameMenu } from "../ui/GameMenu";
import { Notifications } from "../ui/Notifications";
import { PlayerUI } from "../ui/PlayerUI";
import {MultiplayerContext} from "../../matchmaking/context";

export const UIController = (props) => {
    const game = props.game;

    const multiplayerContext = useContext(MultiplayerContext);
    const socket = multiplayerContext.socket;
    const [playerData, setPlayerData] = useState({});

    //  TODO: find out if this is efficient enough or if we need to fetch the data directly from the game
    socket.on('updatePlayersData', (data) => {
        setPlayerData(data[socket.id]);
    });

    return(
        <MDBRow>
            <PlayerUI playerData={playerData} />
            <GameMenu />
            <Notifications />
        </MDBRow>
    )
};