import React, { useState } from "react";

export const MatchmakingContext = React.createContext();

export const MatchmakingContextProvider = (props) => {
    const [activeRooms, setActiveRooms] = useState({});
    const [activeRoomID, setActiveRoomID] = useState();
    const [username, setUsername] = useState(localStorage.getItem('username'));
    const [activeRoomName, setActiveRoomName] = useState('');
    const [activeRoomPlayerCount, setActiveRoomPlayerCount] = useState(0);
    const [isHost, setIsHost] = useState(false);
    const [messageModalActive, setMessageModalActive] = useState(false);
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [selectedBenderType, setSelectedBenderType] = useState('fire');

    const matchmakingContextValue = {
        activeRooms, setActiveRooms,
        activeRoomID, setActiveRoomID,
        username, setUsername,
        activeRoomName, setActiveRoomName,
        activeRoomPlayerCount, setActiveRoomPlayerCount,
        isHost, setIsHost,
        messageModalActive, setMessageModalActive,
        messageModalMessage, setMessageModalMessage,
        selectedBenderType, setSelectedBenderType
    };

    return(
        <MatchmakingContext.Provider value={matchmakingContextValue}>
            {props.children}
        </MatchmakingContext.Provider>
    )
};
