import React from 'react';
import { Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
// import './styles/index.scss';

import io from 'socket.io-client';
import Lobby from './matchmaking/Lobby';
import Room from './matchmaking/Room';
import GameFinder from './matchmaking/GameFinder';
import Queue from './matchmaking/gameFinder/Queue'
import Menu from './matchmaking/Menu'
import { Game } from "./game";
import { MemoryRouter } from 'react-router'
import { GameFinderContextProvider, LobbyContextProvider, MatchmakingContextProvider, MultiplayerContextProvider } from './matchmaking/context';
import { MessageForm } from './matchmaking/general';

// const socket = io("https://server-domain.com");

const socket = io('http://localhost:3001/');
// const socket = openSocket('https://arena-server.rubenzantingh.com/');
socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
});
console.log('this is the game', socket);

const App = () => {
    return (
        <MultiplayerContextProvider socket={socket}>
            <MatchmakingContextProvider>
                <GameFinderContextProvider>
                    <MessageForm />

                    <MemoryRouter>
                        <Routes>
                            <Route path="/lobby"
                               element={
                                   <LobbyContextProvider>
                                       <Lobby socket={socket}/>
                                   </LobbyContextProvider>
                                }
                            />

                            <Route path="/gameFinder"
                                   element={
                                       <GameFinder socket={socket}/>
                                   }
                            />

                            <Route path="/queue"
                                   element={
                                       <Queue socket={socket}/>
                                   }
                            />

                            <Route path="/room"
                                   element={
                                       <Room socket={socket}/>
                                   }
                            />

                            <Route path="/game"
                                   element={
                                       <Game socket={socket} />
                                   }
                            />

                            <Route path="/"
                                   element={
                                       <Menu socket={socket} />
                                   }
                            />
                        </Routes>
                    </MemoryRouter>
                </GameFinderContextProvider>
            </MatchmakingContextProvider>
        </MultiplayerContextProvider>
    );
};

export default App;
