import React from 'react';
import ReactDOM from 'react-dom';

import './styles.css';
import App from './App'

const render = () => { // this function will be reused
    ReactDOM.render(
        <App />,
        document.getElementById('root')
    )
};

render();
