import React, { useState } from "react";
export const GameUIContext = React.createContext();

export const GameUIContextProvider = (props) => {
    const [allowMenuToggle, setAllowMenuToggle] = useState(true);

    const GameUIContextValue = {
        allowMenuToggle, setAllowMenuToggle
    };

    return(
        <GameUIContext.Provider value={GameUIContextValue}>
            {props.children}
        </GameUIContext.Provider>
    )
};
