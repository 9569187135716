import Phaser from 'phaser'
import { GameController } from "../controllers/GameController";
import { WorldBuilderController } from "../controllers/WorldBuilderContoller";

export class ArenaScene extends Phaser.Scene {
    constructor() {
        super('ArenaScene');
    }

    // TODO: Asset/data manager
    preload(){
        this.load.image('player', '/assets/avatar-char.png');
        this.load.image('crosshair', '/assets/crosshair.png');

        this.load.image('fireBall', '/assets/fireball.png');

        //TODO: I cannot change this config
        this.load.json('techniquesConfig', '/configs/Techniques.json');
    }

    create() {
        const socket = this.game.config.socket;

        let self = this;
        this.game = {
            scene: self,
            socket: socket
        };

        this.game.gameController = new GameController(this.game);
        this.cameras.main.setBackgroundColor('#24252A');
    };

    update(time, delta){
        this.game.gameController.update(time, delta);
    }
}