import openSocket from 'socket.io-client';
import { Enemy } from "../multiplayer/Enemy";
import { MultiPlayerObject } from "../multiplayer/MultiPlayerObject";
import { MultiplayerContext } from '../../matchmaking/context'
import React, { useContext } from 'react';
import {Player} from "../player/Player";

export class MultiPlayerController{
    constructor(game){
        this.game = game;
        this.socket = game.socket;

        //  Internal
        this.internalObjectId = 0;
        this.damageObjects = {};
        this.areaObjects = {};
        this.internalObjects = {};

        //  External
        this.enemies = {};

        this.multiplayerObjects = {};

        this.socket.on('updateEnemyPositions', (players) => { this.updateEnemyPositions(players) });
        this.socket.on('enemyDisconnected', this.enemyDisconnected());
        this.socket.on('updateMultiplayerObjectProps', (objects) => { this.updateMultiplayerObjectProps(objects) });
        this.socket.on('removeMultiPlayerObject', (object) => { this.removeMultiPlayerObject(object) });
        this.socket.on('updatePlayersData', (data) => { this.updatePlayersData(data) });
    }

    updatePlayersData(players){
        Object.keys(players).map((playerID) => {
            if(playerID === this.socket.id){
                if(typeof this.game.player !== 'object'){
                    this.game.player = new Player({...{game: this.game}, ...players[playerID]});

                } else {
                    this.game.player.updateData(players[playerID]);
                }
            } else {
                if (typeof this.enemies[playerID] !== 'object') {
                    this.enemies[playerID] = {};
                    this.enemies[playerID] = new Enemy({...{game: this.game}, ...players[playerID]});
                } else {
                    this.enemies[playerID].updateData(players[playerID]);
                }
            }
        });
    }

    //  Update this game's values
    updateEnemyPositions(players){
        Object.keys(players).forEach(key => {
            if(key === this.socket.id){
                return;
            }

            if (typeof this.enemies[key] !== 'object') {
                this.enemies[key] = {};
                this.enemies[key] = new Enemy(this.game, players[key], key);
            } else {
                this.enemies[key].update({position: players[key].position, rotation: players[key].rotation});
            }
        });
    }

    updateMultiplayerObjectProps(objects){
        Object.keys(objects).forEach(key => {
            if(typeof this.multiplayerObjects[key] !== 'object') {
                this.multiplayerObjects[key] = {};
                this.multiplayerObjects[key] = new MultiPlayerObject(this.game, objects[key], key);

            } else {
                this.multiplayerObjects[key].update({position: objects[key].position, rotation: objects[key].rotation});
            }
        });
    }

    removeMultiPlayerObject(object){
        this.multiplayerObjects[object.id].objectSprite.destroy();
        delete this.multiplayerObjects[object.id];
    }

    enemyDisconnected(){
        console.log('Player disconnected');
    }

    emitUseTechnique(techniqueID){
        const crosshairPosition = {x: this.game.player.playerMovement.crosshair.x, y: this.game.player.playerMovement.crosshair.y};
        this.socket.emit('playerUseTechnique', {playerID: this.socket.id, benderType: this.game.player.benderType, techniqueID: techniqueID, crosshairPosition: crosshairPosition});
    }

    //  Emit this game's values
    emitPlayerData (data) {
        this.socket.emit('playerUpdateData', data);
    };

    //  Call update function in all objects to emit their values
    update(time, delta){
        // Object.keys(this.internalObjects).forEach(key => { this.internalObjects[key].update(time, delta); });
    }
}