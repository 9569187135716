import React, { useContext } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit";
import { GameFinderContext, MatchmakingContext, MultiplayerContext } from '../context'
import { useNavigate  } from "react-router-dom";

const Queue = () => {
    const gameFinderContext = useContext(GameFinderContext);
    const matchmakingContext = useContext(MatchmakingContext);
    const multiplayerContext = useContext(MultiplayerContext);
    const socket = multiplayerContext.socket;
    let navigate = useNavigate ();

    socket.emit('joinGameFinder', {selectedQueueType: gameFinderContext.selectedQueueType, username: matchmakingContext.username, benderType: gameFinderContext.selectedBenderType});

    socket.on('gameCreationInstantiated', (data) => {
        const players = data.players;
        const playersNeeded = Object.keys(players).length;

        if(players[socket.id]){
            //TODO: pass bender type to game

            socket.emit('joinGame', {players: players, gameID: data.gameID, playersNeeded: playersNeeded});
            navigate('/game');
        }
    });

    const leaveGameFinder = () => {
        socket.emit('leaveGameFinder', {selectedQueueType: gameFinderContext.selectedQueueType});
        navigate('/gameFinder');
    };

    return(
        <MDBContainer fluid className="pb-4 pt-4 orange lighten-3 lobby-container">
            <MDBContainer>
                <MDBRow className="h-auto">
                    <MDBCol bg="#fff" className="d-flex justify-content-center">
                        <h1>Finding game ...</h1>
                    </MDBCol>

                    <MDBCol md="12" className="mt-5 d-flex justify-content-center">
                        <MDBBtn onClick={() => {leaveGameFinder() }} size="lg" color="primary">Leave game finder</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBContainer>
    )
};

export default Queue;