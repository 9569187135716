export class Enemy {
    constructor(props){
        this.game = props.game;
        this.scene = this.game.scene;

        this.enemySprite = this.scene.physics.add.sprite(1000, 200, 'player');
    }

    updateData(data){
        this.enemySprite.x = data.playerPosition.x;
        this.enemySprite.y = data.playerPosition.y;
    }
}