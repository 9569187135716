export class MultiPlayerObject {
    constructor(game, object, id) {
        this.game = game;
        this.scene = game.scene;
        this.id = id;


        //  TODO: edit the size lol
        this.objectSprite = this.scene.physics.add.sprite(object.position.x, object.position.y, object.sprite);
        this.objectSprite.displayWidth = object.spriteSize.x;
        this.objectSprite.displayHeight = object.spriteSize.y;
    }

    update(data) {
        const position = data.position;
        const rotation = data.rotation;

        this.objectSprite.x = position.x;
        this.objectSprite.y = position.y;
        this.objectSprite.rotation = rotation;
    }
}